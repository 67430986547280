import React, { Component }  from 'react';
import { useParams, useNavigate,  } from "react-router-dom";
import { Form, Modal, Button} from 'antd';
import { useTranslation } from 'react-i18next';
import CryptoJS from "crypto-js";
import { destroyToken } from './axiosRequest';


export function numberWithSpaces(x) {
  if(x){
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }
}

export function cleanText(x) {
  if(x.indexOf('$')>0){
    return x.substring(x.indexOf('$') + 4, x.length);
  }
  return x;
}

export function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function today() {
  var _today = new Date();
  var dd = String(_today.getDate()).padStart(2, '0');
  var mm = String(_today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = _today.getFullYear();

  return dd + '.' + mm + '.' + yyyy;
}

export function API_KEY() {
  return 'bfb9f8d8071553ff521ed26bcd0452e5d06e36c92284cc253608550f8af7d757'
  
  
}

export function errorHandler (response, function_name, navigate) {

  if (response.status === 200) {
    return response.json()}
  else if(response.status === 401 || response.status === 403) {
    destroyToken();
    Modal.warning({
      title:'Сессия тамом шуд!',
      content: (<div>Вақти сессияи браузер тамом шуд. Илтимос ба система аз дигар ворид шавед!</div>),
    });
    
    navigate('/');
  }
  else if(response.status === 409 || response.status === 422) {
    response.json()
      .then(respjson =>{
    
    const err =  respjson

    console.log(err);
    Modal.error({
      onOk() {
      fetch(`https://smartpay.tj/api/billing/error`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + window.sessionStorage["access_token"],
        },
        body: JSON.stringify({
          'api_url': response.url,
          'function_name': function_name, 
          'server_error': err.detail,
          'status': response.status,
        })
      })
    },
    
      title:'Амалиёти нодуруст!',
      content: (
        <div className='flex w-full mb-2'>
          Ба дархости шумо аз тарафи сервер чавоби нодуруст омад. 
          Барои баланд бардоштани сифати барнома, маълумотҳои техникӣ ба Smartpay равон карда мешавад.
        </div>),
      
    });
    });
    return null
  }  
  else {
    Modal.warning({
      title:'Дархости нодуруст!',
      content: (<div>Дархост дар тарафи сервер нодуруст иҷро шуд!</div>),
    });
    
  }
}

export const withRouter = WrappedComponent => props => {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('','', '', { useSuspense: false });
    const [form]= Form.useForm();
    return (
      <WrappedComponent
        {...props}
        params={params}
        navigate = {navigate}
        translation = {t}
        form = {form}
        
        
      />
    );
  };

export default { uuidv4, today, numberWithSpaces, withRouter }