import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate, useRoutes } from "react-router-dom";
import "./App.css";
import Spinner from "./components/Spinner/Spinner";
import Login from "./model/Login/Login";
import {
  InvoiceForm,
  InvoiceForms,
  Layout,
  Panel,
  Profile,
  ProfileOrg,
  Recipients,
  Services,
  Users,
  Billing,
} from "./routes/routes";
import { getToken } from "./utils/axiosRequest";
import { RequireAuth } from "./utils/RequreAuth";
import Payments from "./view/Invoices/Payments";


function App() {
  // sessionStorage.setItem("isLogged", false);
  const [isLogged, setIsLogged] = useState(false);
  const navigate = useNavigate();


let operatorRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Billing />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "billing",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Billing />
              </RequireAuth>
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForms />
                  </RequireAuth>
                </Suspense>
              ),
            },
            {
              path: ":formId",
              element: (
                <Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <InvoiceForm />
                  </RequireAuth>
                </Suspense>
              ),
            },
            
          ],
        },

        {
          path: "payments",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Payments />
              </RequireAuth>
            </Suspense>
          ),
        },

        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  let supervisorRoutes = [
    {
      path: "/panel",
      element: (
        <Suspense fallback={<Spinner />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Billing />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "billing",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Billing />
              </RequireAuth>
            </Suspense>
          ),
          
        },
        
        {
          path: "users",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Users />
              </RequireAuth>
            </Suspense>
          ),
        },
        
        {
          path: "profileOrg",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <ProfileOrg />
              </RequireAuth>
            </Suspense>
          ),
        },
        {
          path: "profile",
          element: (
            <Suspense fallback={<Spinner />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </Suspense>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("isLogged"))) {
      setIsLogged(JSON.parse(sessionStorage.getItem("isLogged")));
    }
  }, [isLogged, navigate]);

  let renderOperatorRoutes = useRoutes(operatorRoutes);
  //let renderAdministratorRoutes = useRoutes(administratorRoutes);
  let renderSupervisorRoutes = useRoutes(supervisorRoutes);
  //let renderSupervisorDisableRoutes = useRoutes(supervisorDisableRoutes);
  //let renderPerformerRoutes = useRoutes(performerRoutes);

  return (
    <>
      
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
          {getToken()?.role_id === 3 && renderOperatorRoutes}
      
          {getToken()?.role_id === 1 && getToken().disable === false && renderSupervisorRoutes}
        
      
        
      
    </>
  );
}

export default App;
