import React from "react";
import { numberWithSpaces, withRouter, uuidv4, errorHandler } from "../../utils/GlobalFunctions"
import { Component } from "react";
import { LoadingOutlined, IdcardOutlined, FileDoneOutlined, SaveOutlined, CloseSquareOutlined, PrinterOutlined } from '@ant-design/icons';
import { Spin, Table, Pagination, Drawer, Form, Input, Row, Tabs, Button, ConfigProvider, DatePicker, Modal } from 'antd'; 
import { StopOutlined, CheckCircleOutlined, SyncOutlined, FileExcelOutlined} from '@ant-design/icons';
//import AccountForm from "./AccountForm";
import moment from "moment";
const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );
const { Search } = Input;

export class Payments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            payments_data: null,
            pageSize: 20,
            currentPage: 1,
            searchValue: '',
            tableUpdate: false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`https://smartpay.tj/api/billing/payments?skip=${(this.state.currentPage-1) * this.state.pageSize}&limit=${this.state.pageSize}`, 
        { 
            method: "GET", 
            headers: { "Content-Type": "application/json",'Authorization': 'Bearer ' + window.sessionStorage["access_token"]},
        })
        .then((response) => {
                return response.json();     
            })
        .then((responseJson) => {
                this.setState({ isLoaded: true, payments_data : responseJson });
            });
    }

    onHandlePageChange = (page, size) => {
        this.setState({currentPage: page, pageSize: size, tableUpdate: true});

        fetch(`https://smartpay.tj/api/billing/payments?skip=${(page-1)*size}&limit=${size}`, 
        { 
            method: "GET", 
            headers: { "Content-Type": "application/json",'Authorization': 'Bearer ' + window.sessionStorage["access_token"]},
        })
        .then((response) => {
                return response.json();     
            })
        .then((responseJson) => {
                this.setState({ isLoaded: true, payments_data : responseJson, tableUpdate: false });
            });
    }

    render () {
        const { isLoaded, payments_data, currentPage, pageSize } = this.state;
        const { translation } = this.props;
        const payment_columns = [
            {
                title: translation("DATE"),
                width: "12%",
                dataIndex: 'date',
                render: (date)=>{
                    return <div className="text-[12px]">{date}</div>
                }
            },
            {
                title: translation("GROUP"),
                width: "5%",
                dataIndex: 'level',
                render: (level)=>{
                    return <div className="text-[12px]">{level}</div>
                }
            },

            {
                title: translation("ACCOUNT_ID"),
                width: "12%",
                dataIndex: 'account_num',
                render: (account_num)=>{
                    return <div className="text-[12px]">{account_num}</div>
                }
            },
            
            {
                title: translation('NAME'),
                width: "15%",
                dataIndex: 'name',
                render: (name)=>{
                    return <div className="text-[12px]">{name}</div>
                }
            },
            {
                title: translation("PHONE_NUMBER"),
                width: "10%",
                dataIndex: 'phonenumber',
                render: (phonenumber)=>{
                    return <div className="text-[12px]">{phonenumber}</div>
                }
            },
            {
                title: translation("AMOUNT1"),
                width: "8%",
                align: "center",
                dataIndex: 'amount_paid',
                render: (amount_paid) => {
                   return <div className="text-emerald-600">{numberWithSpaces(amount_paid.toFixed(2))}</div>
                }
            },
            {
                title: translation("BANK_NAME1"),
                width: "20%",
                dataIndex: 'bank',
                render: (bank)=>{
                    return <div className="text-[12px]">{bank}</div>
                }
            },
        ];
    return(<>
    {!isLoaded 
        ?<div className="flex w-full h-[40vh] justify-center items-center"><Spin indicator={antIcon} /></div>
        :
        <div>
            {this.state.tableUpdate ? <>
                <div className="w-full h-[10rem] flex items-center justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
            </>:<>
            <div className="ml-4 font-semibold">
                Фехристи пардохтҳо 
            </div>
                <Pagination
                className="my-[2rem] ml-4"
                onChange={this.onHandlePageChange}
                onShowSizeChange={this.onHandlePageChange}
                total={payments_data['stat']}
                current={currentPage}
                pageSize={pageSize}
                hideOnSinglePage={true}
              />
            <Table
                columns={payment_columns} 
                dataSource={payments_data['payments']}
                pagination={false}
                sticky
                size="small">
            </Table>
            </>}
        </div>
    }</>
            
    );
    }
}

export default withRouter(Payments) ;

