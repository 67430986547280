import axios from "axios";
// import createAuthRefreshInterceptor from "axios-auth-refresh";
import jwt_decode from "jwt-decode";

const axiosLogin = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

const axiosRequest = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

axiosRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      return (window.location.href = "/");
    }
    return Promise.reject(error);
  }
);

axiosRequest.interceptors.request.use((config) => {
  
  config.headers.Authorization = `Bearer ${sessionStorage.getItem(
    "access_token"
  )}`;

  return config;
});

const axiosRequestBilling = axios.create({
  withCredentials: true,
  baseURL: 'https://smartpay.tj/api/',
});

axiosRequestBilling.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      return (window.location.href = "/");
    }
    return Promise.reject(error);
  }
);

axiosRequestBilling.interceptors.request.use((config) => {
  
  config.headers.Authorization = `Bearer ${sessionStorage.getItem(
    "access_token"
  )}`;

  return config;
});

// Function that will be called to refresh authorization
// const refreshAuthLogic = (failedRequest) =>
//   axiosRequest.get(`token_refresh`).then((tokenRefreshResponse) => {
//     sessionStorage.setItem(
//       "access_token",
//       tokenRefreshResponse.data.access_token
//     );
//     failedRequest.response.config.headers["Authorization"] =
//       "Bearer " + tokenRefreshResponse.data.access_token;
//     return Promise.resolve();
//   });

// Instantiate the interceptor
// createAuthRefreshInterceptor(axiosRequest, refreshAuthLogic);

function saveToken(access_token, refresh_token) {
  sessionStorage.setItem("access_token", access_token);
  //   sessionStorage.setItem("refresh_token", refresh_token);
}

function getToken() {
  try {
    return jwt_decode(sessionStorage.getItem("access_token"));
  } catch (error) {}
}

function destroyToken() {
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("isLogged");
  //   sessionStorage.removeItem("refresh_token");
}
export { axiosLogin, axiosRequest, axiosRequestBilling, saveToken, getToken, destroyToken };
